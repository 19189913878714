import {Controller} from "stimulus"


export default class extends Controller {
    static targets = ["modal"]

    connect() {
        console.log("log from ap-bill-vendor controller");
        const form = this.element;
        form.dataset.modified = 'false';

        form.addEventListener('change', () => {
            console.log("form changed");
            form.dataset.modified = 'true';
        });
    }

    selectVendor(e) {
        console.log("selectVendor");
        const vendorField = document.getElementById("accounts_payable_bill_vendor_id")
        const vendorId = e.target.closest("li").id
        vendorField.value = vendorId;
        e.target.closest("li").classList.add("active");
        const resultsArea = e.target.closest(".search-container").querySelector(".bl-search-results");
        resultsArea.classList.remove("showing");
        resultsArea.innerHTML = '';
        const frame = document.querySelector("turbo-frame#bill_invoice_frame");
        frame.src = `/invoicing/invoices/new?vendor_id=${vendorId}`

    }

    handlePaymentClick(event) {
        event.preventDefault();
        const form = this.element.closest('form');

        if (form.dataset.modified === 'true') {
            // Add hidden field for opening modal after reload
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = 'open_payment_modal';
            hiddenField.value = 'true';
            form.appendChild(hiddenField);

            form.requestSubmit();
        } else {
            console.log("form not modified, opening modal");
            const modal = document.querySelector('[data-controller="tailwind-modal"]');
            if (modal) {
                console.log("found modal:");
                console.log(modal);
                const event = new CustomEvent('toggle-modal');
                modal.dispatchEvent(event);
            }
        }
    }
}