import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        console.log("tailwind-modal-controller");
        this.element.addEventListener('toggle-modal', () => {
            this.toggleModalRemote();
        });
    }

    toggleModal(event) {
        event.preventDefault();
        console.log("toggle modal");
        let modal = event.target.closest(".tailwind-modal-area").querySelector('.tailwind-modal');
        modal.classList.toggle('hidden');
    }

    toggleModalRemote() {
        console.log("toggleModalRemote");
        const modal = this.element.querySelector('.tailwind-modal');
        if (modal) {
            modal.classList.toggle('hidden');
        }
    }
}

