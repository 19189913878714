import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["searchResults", "searchItem"]

    connect() {
        console.log("log from searchForm controller")
        this.timeout = null
        this.currentController = null
        this.lastSearchValue = ""

        if (this.hasSearchItemTarget) {
            this.searchItemTarget.addEventListener("search", (event) => {
                const resultsArea = event.target.closest(".search-container").querySelector(".bl-search-results")
                resultsArea.classList.remove("showing")
                resultsArea.innerHTML = ''
            })
        }

        if (this.hasSearchResultsTarget) {
            this.searchResultsTarget.addEventListener("click", (event) => {
                if (event.target.closest('a')) { // Check if clicked element is or is within a link
                    if (this.currentController) {
                        this.currentController.abort()
                    }
                    clearTimeout(this.timeout)
                    this.searchResultsTarget.classList.remove("showing")
                }
            })
        }
    }

    clearResults() {
        const resultsArea = this.searchResultsTarget
        resultsArea.querySelector("#search_results").innerHTML = ''
    }

    requestSearch(event) {
        // Handle Enter - submit form normally
        if (event.key === "Enter") {
            event.target.closest('form').submit()
            return
        }

        // Handle Escape - abort current searches and clear results
        if (event.key === "Escape") {
            if (this.currentController) {
                this.currentController.abort()
            }
            clearTimeout(this.timeout)
            const resultsArea = event.target.closest('.search-container').querySelector('.bl-search-results')
            resultsArea.classList.remove("showing")
            resultsArea.innerHTML = ''
            return
        }

        // Regular search handling
        clearTimeout(this.timeout)
        const resultsArea = event.target.closest('.search-container').querySelector('.bl-search-results')
        const searchValue = this.searchItemTarget.value

        // Clear results if search value changed
        if (searchValue !== this.lastSearchValue) {
            this.clearResults()
            this.lastSearchValue = searchValue
        }
        this.timeout = setTimeout(() => {
            const searchValue = this.searchItemTarget.value
            if (searchValue.length > 2) {
                resultsArea.classList.add("showing")
                let searchTypes = /^\D{2}-\d{1,9}$/.test(searchValue)
                    ? ['id']
                    : event.target.dataset.searchformScope.split(",")

                // Abort any existing searches
                if (this.currentController) {
                    this.currentController.abort()
                }
                this.currentController = new AbortController()

                searchTypes.forEach(searchType => {
                    console.log("searching for " + searchType + " with value " + searchValue)
                    fetch(`/search/query?type=${searchType}&search=${searchValue}`, {
                        headers: {
                            "Accept": "text/vnd.turbo-stream.html",
                            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
                        },
                        signal: this.currentController.signal
                    })
                        .then(response => response.text())
                        .then(html => {
                            if (this.searchItemTarget.value === searchValue) {  // Only update if value hasn't changed
                                Turbo.renderStreamMessage(html)
                            }
                        })
                        .catch(error => {
                            if (error.name === 'AbortError') {
                                console.log('Fetch aborted')
                            } else {
                                console.error('Fetch error:', error)
                            }
                        })
                })
            } else {
                resultsArea.classList.remove("showing")
            }
        }, 300)
    }

    requestIdSearch(event) {
        clearTimeout(this.timeout)
        const abortSignal = new AbortController();
        var component = this;
        const resultsArea = event.target.closest('.search-container').querySelector('.bl-search-results');
        if (event.which == 13 || event.keyCode == 13) {
            console.log('aborting Type Ahead search');
            abortSignal.abort();
            resultsArea.classList.remove("showing")
        }
        this.timeout = setTimeout(function () {
            if (component.searchItemTarget.value.length > 2) {
                resultsArea.classList.add("showing");
                fetch("/search/query.html?search=" +
                    encodeURIComponent(component.searchItemTarget.value) +
                    "&type=" + encodeURIComponent(event.target.dataset.scope) +
                    "&more=" + encodeURIComponent(event.target.dataset.more) +
                    "&limit=" + encodeURIComponent((event.target.dataset.limit || 5)) +
                    "&excluded=" + encodeURIComponent((event.target.dataset.excludedIds || "[]")),
                    {abortSignal})
                    .then(response => {
                        return response.text();
                    })
                    .then(html => {
                        resultsArea.innerHTML = html;
                    }).catch(error => {
                    console.warn(error);
                    Honeybadger.notify(error)
                    document.querySelector('#flash-area').innerHTML = "<p class=\"notice\">" + error.message + "</p>";
                })
                ;
            } else {
                resultsArea.classList.remove("showing")
            }

        }, 1100)
    }

    disconnect() {
        clearTimeout(this.timeout)
        if (this.currentController) {
            this.currentController.abort()
        }
    }
}