import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        this.initializeCloudinaryWidget()
    }

    initializeCloudinaryWidget() {
        if (window.myWidget) return

        if (!document.querySelector("script[src='https://upload-widget.cloudinary.com/global/all.js']")) {
            const cloudinaryScript = document.createElement("script")
            cloudinaryScript.src = "https://upload-widget.cloudinary.com/global/all.js"
            cloudinaryScript.type = "text/javascript"
            cloudinaryScript.async = true
            document.head.appendChild(cloudinaryScript)
            cloudinaryScript.onload = () => this.createWidget()
        } else {
            this.createWidget()
        }
    }

    createWidget() {
        const preset = this.element.dataset.cloudinaryPreset

        window.myWidget = cloudinary.createUploadWidget({
            cloudName: 'govpossible-pbc',
            upload_preset: preset,
            sources: ["local", "camera"],
            cropping: false,
            styles: {
                palette: {
                    window: "#FFFFFF",
                    windowBorder: "#90A0B3",
                    tabIcon: "#1891F7",
                    menuIcons: "#5A616A",
                    textDark: "#0F2E51",
                    textLight: "#FFFFFF",
                    link: "#1891F7",
                    action: "#FF620C",
                    inactiveTabIcon: "#3F5A81",
                    error: "#F55A68",
                    inProgress: "#0078FF",
                    complete: "#34D09B",
                    sourceBg: "#e0e4f5"
                },
                fonts: {
                    default: null,
                    "'Poppins', sans-serif": {
                        url: "https://fonts.googleapis.com/css?family=Poppins",
                        active: true
                    }
                }
            }
        }, (error, result) => {
            if (result.event === "success") {
                fetch('/graphics', {
                    method: 'POST',
                    credentials: "same-origin",
                    headers: {
                        "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        graphics: {
                            graphicable_id: this.element.dataset.graphicableId,
                            graphicable_type: this.element.dataset.graphicableType,
                            stored_at: result.info['public_id'],
                            label: result.info['original_filename']
                        }
                    })
                }).then(response => response.text())
                    .then((response) => {
                        document.querySelector(".graphics-area").innerHTML = response
                    })
                    .catch((error) => console.log(error))
            }
        })

        // Add click handler
        const uploadButton = this.element.querySelector(".image-upload-button")
        if (uploadButton) {
            uploadButton.addEventListener("click", () => window.myWidget.open())
        }
    }




    showGraphic(e) {
        e.preventDefault();
        const graphicId = e.target.closest('.graphic-card').querySelector(".graphic-id").value;
        fetch("/graphics/" + graphicId + "/edit  ", {
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then(response => response.text())
            .then(html => {
                    const displayArea = document.querySelector(".display-area");
                    displayArea.innerHTML = html;
                    displayArea.classList.remove('hidden');

                }
            );

    }

    deleteGraphic(e) {
        console.log("delete graphic");
        let deleteConfirmed = confirm("Are you sure you want to delete this image?");
        if (deleteConfirmed) {
            const graphicId = e.target.closest('.graphic-overlay').querySelector(".graphic-id").value;
            fetch("/graphics/" + graphicId + '.json', {
                method: 'DELETE',
                credentials: "same-origin",
                headers: {
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                    "Content-Type": "application/json"
                }
            }).then(response => response.text())
                .then(response => {
                    document.querySelector(".graphics-area")
                        .innerHTML = response;
                    document.querySelector(".image-upload-button").addEventListener("click", function () {
                        console.log('image upload requested');
                        myWidget.open();
                    }, false);
                });


        }
    }

    // updateGraphic(e) {
    //     const [data, status, xhr] = e.detail;
    //     document.querySelector(".graphics-area")
    //         .innerHTML = xhr.response;
    //     document.querySelector(".image-upload-button").addEventListener("click", function () {
    //         console.log('image upload requested');
    //         myWidget.open();
    //     }, false);
    // }


    closeForm() {
        const displayArea = document.querySelector(".display-area");
        displayArea.classList.add('hidden');

    }

}
