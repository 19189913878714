import {Controller} from "stimulus"
import {validateFields, formSubmittable} from "../../../lib/assets/form-validation";

export default class extends Controller {

    connect() {
        console.log("log from planning-cases controller");
        if (document.querySelector(".application-parcels") != null) {
            Array.from(document.querySelector(".application-parcels").querySelectorAll(".delete-action")).map(action => {
                action.classList.remove("hidden");
            });
        }


    }

    updateFormFields(e) {
        const form = e.target.closest('form');
        const appTemplateId = e.target.value
        const fieldList = form.querySelector(".field-scope");
        let link = 'update_fields?permitting_app_template_id=' + appTemplateId
        if (window.location.pathname.includes("add_child")) {
            link = '/planning/cases/new/update_fields?permitting_app_template_id=' + appTemplateId
        }
        link += "&field_scope=" + fieldList.value;
        console.log("updating form to case Template" + appTemplateId)
        fetch(link)
            .then(response => {
                return response.text();
            })
            .then(html => {
                document.getElementById("template-specific-fields").innerHTML = html;
            })
        validatePlanningCaseForm(form)
    }

    checkDuplicates(e) {
        const identifier = 'pl-' + e.target.value;
        fetch("/search/query?type=['id']&search=" + identifier)
            .then(response => {
                return response.text()
            })
            .then(data => {
                    console.info(data)
                    if (JSON.parse(data)['planning/cases'].length > 0) {
                        const duplicateId = JSON.parse(data)['planning/cases'][0]['table']['searchable_id']
                        e.target.closest('.duplicate-finder').querySelector(".duplicate-identifier").innerText = identifier
                        e.target.closest('.duplicate-finder').querySelector(".duplicate-link-action").setAttribute('href', duplicateId + "/link_to")
                        e.target.closest('.duplicate-finder').querySelector(".duplicate-found-action").classList.remove("hidden")

                    } else {
                        e.target.closest('.duplicate-finder').querySelector(".duplicate-found-action").classList.add("hidden")
                    }
                }
            )
    }


    validateForm(e) {
        const form = e.target.closest('form');
        validatePlanningCaseForm(form)
    }

    updateSelectedParcels(e) {
        if (!(e.target.closest('li').classList.contains('selected'))) {
            console.log('updating selected parcels!')
            const parcelSummaryArea = document.querySelector(".application-parcels").querySelector("ul");
            const chosenParcel = e.currentTarget;
            e.currentTarget.querySelector(".delete-action").classList.remove("hidden");
            chosenParcel.classList.add('selected');
            parcelSummaryArea.appendChild(chosenParcel);
        }
    }

    removeParcel(e) {
        let deleteConfirmed = confirm("Are you sure you want to remove this parcel from the application?");
        if (deleteConfirmed) {
            const parcelSummaryArea = document.querySelector(".application-parcels").querySelector("ul");
            const chosenParcel = e.currentTarget.closest('li');
            const parcelId = chosenParcel.id;
            const parcelFields = Array.from(document.querySelector(".selected-parcels").querySelector(".results-list").querySelectorAll("[data-location-point-id='" + parcelId + "']"))
            chosenParcel.classList.remove('selected');
            e.currentTarget.classList.add('hidden');
            parcelSummaryArea.removeChild(chosenParcel);
            parcelFields.map(field => {
                field.value = 1;
            })
        }
    }
}

function validatePlanningCaseForm(form) {
    validateFields(form);
    formSubmittable(form);

}
